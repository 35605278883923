import { computed, onMounted, ref } from 'vue';
import { getEnterprises, searchFavorites } from '@/api';
import type { FavoriteItem } from '@/views/favorite/typing.ts';
import { $t } from '@repo/locales';
import { dateRange } from '@repo/utils';

let favoriteFabricInstance: ReturnType<typeof createFavoriteFabricInstance> | null = null;

function createFavoriteFabricInstance() {
  const results = ref<FavoriteItem[]>([]);

  const date = ref(getDefaultDateOption());
  const dateOptions = ref([
    { text: $t('favorite-page.today'), value: 0 },
    { text: $t('favorite-page.this-week'), value: 1 },
    { text: $t('favorite-page.this-month'), value: 2 },
    { text: $t('favorite-page.all-time'), value: -1 }
  ]);

  const range = computed<[number, number] | undefined>(() => {
    switch (date.value) {
      case 0:
        return dateRange('day');
      case 1:
        return dateRange('week');
      case 2:
        return dateRange('month');
    }
    return undefined;
  });

  const enterprise = ref<string>('');
  const enterpriseOptions = ref([{ text: $t('favorite-page.all-company'), value: '' }]);

  async function loadData(init = false, _page = 1, keyword: string[] = []) {
    const resp = await searchFavorites(keyword[0], 9999, enterprise.value, range.value);
    results.value = [...(!init ? results.value : []), ...resp];
    // 返回空数组，表示没有更多数据
    return [];
  }

  onMounted(async () => {
    await getEnterpriseOptions();
  });

  async function getEnterpriseOptions() {
    const enterprises = await getEnterprises();
    enterpriseOptions.value = [...enterprises.map(item => ({ text: item.name, value: item.id })), {
      text: $t('favorite-page.all-company'),
      value: ''
    }];
  }

  return { results, loadData, date, dateOptions, enterprise, enterpriseOptions };
}

export const useFavoriteFabric = () => {
  if (!favoriteFabricInstance) {
    favoriteFabricInstance = createFavoriteFabricInstance();
  }
  return favoriteFabricInstance;
};

function getDefaultDateOption() {
  return parseInt(localStorage.getItem('favorite-page-date-option') || '-1');
}
