import { ref, inject, provide, type InjectionKey, type Ref, onMounted } from 'vue';


export interface AddressBook {
  company?: string;
  contact: string;
  phone: string;
  address: string;
  memo?: string;
}

interface AddressBookProvider {
  showModal: Ref<boolean>;
  simplify: Ref<boolean>;
  toggleModal: (show: boolean) => void;
  addressBook: Ref<AddressBook>;
  save: () => void;
  getAddressBook: () => AddressBook | null;
}

type validatorFunc = (address: AddressBook) => boolean;

function defaultValidator(address: AddressBook): boolean {
  return !!address.phone;
}

const addressBookKey = Symbol('address-book') as InjectionKey<AddressBookProvider>;

export function useAddressBookProvider(): AddressBookProvider {
  const showModal = ref(false);

  const simplify = ref(false);

  const addressBook = ref<AddressBook>({
    company: '',
    contact: '',
    phone: '',
    address: '',
    memo: ''
  });

  onMounted(() => {
    const val = localStorage.getItem('address-book');
    if (val) {
      addressBook.value = JSON.parse(val);
    }
  });

  function toggleModal(state: boolean) {
    showModal.value = state;
  }

  function save() {
    localStorage.setItem('address-book', JSON.stringify(addressBook.value));
  }


  function getAddressBook(validator: validatorFunc = defaultValidator) {
    if (!validator(addressBook.value)) {
      return null;
    }
    return addressBook.value;
  }


  provide(addressBookKey, { showModal, toggleModal, addressBook, save, getAddressBook, simplify });

  return {
    showModal,
    toggleModal,
    addressBook,
    save,
    getAddressBook,
    simplify
  };
}

export function useAddressBook(simply: boolean = false): AddressBookProvider {
  const addressBook = inject(addressBookKey);

  if (!addressBook) {
    throw new Error('useLoading must be used within a useAddressBookProvider');
  }

  addressBook.simplify.value = simply;
  return addressBook;
}
