<script setup lang="ts">
import { DropdownItem } from 'vant';
import { watch } from 'vue';
import { useFavoriteFabric } from '@/views/cloud/components/favorite-fabric/use-favorite-fabric.ts';

const emit = defineEmits<{
  change: []
}>();
const { date, enterprise, dateOptions, enterpriseOptions } = useFavoriteFabric();

watch([date, enterprise], () => {
  emit('change');
}, {
  immediate: true
});
</script>

<template>
  <DropdownItem v-model="date" :options="dateOptions" />
  <DropdownItem v-model="enterprise" :options="enterpriseOptions" />
</template>

<style scoped>

</style>
