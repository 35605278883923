<script setup lang="ts">
import { ref, toRaw } from 'vue';
import { Button, Dialog, Field, Form, showToast } from 'vant';
import { type ResourceType } from '@/api';
import { $t } from '@/locales';
import { useFavoriteListStore } from "@/store/favorite-list.ts";

defineOptions({ inheritAttrs: false });

const props = withDefaults(defineProps<{
  id?: string,
  thumb?: string,
  enterpriseId?: string,
  resourceType?: ResourceType,
  properties?: Record<string, string>[];
  enterpriseName?: string,
  info?: Record<string, any>,
  memo?: string,
  num?: number,
  showForm?: boolean
}>(), {
  resourceType: 'cloudknit'
});

const emit = defineEmits<{
  (e: 'success', payload: { id: string, memo: string, num: number }): void
}>();


const showModal = ref(false);
const model = ref<{ num: number, memo: string }>({
  num: props.num || 1,
  memo: props.memo || ''
});

const favoriteListStore = useFavoriteListStore();

const onFavorite = async () => {
  if (!props.id) {
    return;
  }
  const { id, thumb, enterpriseId, enterpriseName, info, resourceType } = props;
  const { memo, num } = model.value;

  await favoriteListStore.addFavorite({
    id,
    resourceType,
    info: toRaw(info),
    thumb: thumb || '',
    enterpriseId,
    enterpriseName,
    properties: toRaw(props.properties || []),
    memo,
    num
  })
  showModal.value = false;
  showToast($t('message.favorite-success'));
  emit('success', { id, memo, num });
};

const onClick = async () => {
  if (props.showForm) {
    showModal.value = true;
  } else {
    await onFavorite();
  }
};
</script>

<template>
  <div v-bind="$attrs" @click="onClick">
    <slot />
  </div>
  <Dialog v-model:show="showModal" :show-confirm-button="false" close-on-click-overlay>
    <Form :class="$style.dialogContent">
      <Field
        v-model="model.memo"
        name="memo"
        type="textarea"
        :label="$t('common.memo')"
        :placeholder="$t('common.memo')"
      />
      <Field
        v-model.number="model.num"
        name="num"
        type="digit"
        :label="$t('common.quantity')"
        :placeholder="$t('common.quantity')"
      />
      <div style="display: flex; justify-content: flex-end; column-gap: 10px;">
        <Button type="primary" size="small" @click.stop="onFavorite">
          {{ $t('common.confirm') }}
        </Button>
      </div>
    </Form>
  </Dialog>
</template>

<style module>
.dialogContent {
  padding: 5px;
}
</style>
