<script setup lang="ts">
import { setWindowTitle } from '@repo/utils';
import { getAppConfig } from '@/utils';
import { LoadingProvider } from '@repo/components';
import { AddressBookProvider } from '@/components';
import { getLocale } from '@/locales';

const { DEFAULT_COMPANY_NAME, DEFAULT_COMPANY_NAME_EN } = getAppConfig();
setWindowTitle(getLocale() === 'en' ? DEFAULT_COMPANY_NAME_EN : DEFAULT_COMPANY_NAME);
</script>

<template>
  <LoadingProvider>
    <AddressBookProvider>
      <RouterView />
    </AddressBookProvider>
  </LoadingProvider>
</template>
<style scoped>
</style>
