import { DBWrapper } from './db-wrapper.ts';

import type { DB } from './typing.ts';

export const db = new DBWrapper<DB>('share-fabric', 3, (db, oldVersion, _newVersion, transaction) => {
  if (oldVersion < 1) {
    const favoriteStore = db.createObjectStore('favorites', {
      keyPath: 'id'
    });
    favoriteStore.createIndex('by-date', 'date');
    favoriteStore.createIndex('fulltext', 'terms', { multiEntry: true });

    const projectStore = db.createObjectStore('projects', {
      keyPath: 'id'
    });
    projectStore.createIndex('by-date', 'date');
  }
  if (oldVersion < 2) {
    const tryonsStore = db.createObjectStore('tryons', {
      keyPath: 'id',
      autoIncrement: true
    });
    tryonsStore.createIndex('by-date', 'date');
    tryonsStore.createIndex('fulltext', 'terms', { multiEntry: true });
  }

  if (oldVersion < 3) {
    const favoriteStore = transaction.objectStore('favorites');
    favoriteStore.createIndex('by-enterprise', 'enterpriseId');
  }
});

