import type { RouteRecordRaw } from 'vue-router';
import { FabricPage } from '@/views/fabric';
import { FavoritePage } from '@/views/favorite';
import { TryOnPage } from '@/views/tryon';
import { TailorPage } from '@/views/tailor';
import { MyTryOnPage } from '@/views/mytryon';
import { BasicLayout, TabbarLayout } from '@/layouts';
import { DetailPage } from '@/views/detail';
import { CloudPage } from '@/views/cloud';

export const routes: RouteRecordRaw[] = [
  {
    component: TabbarLayout,
    name: 'home',
    path: '/',
    redirect: '/fabric/default',
    children: [
      {
        name: 'project',
        path: '/:id',
        alias: '/project/:id',
        component: FabricPage
      },
      {
        name: 'fabric',
        path: '/fabric/:type?/:id',
        component: FabricPage
      },
      {
        name: 'favorite',
        path: '/favorite',
        component: FavoritePage
      },
      {
        name: 'tailor',
        path: '/tailor',
        component: TailorPage
      },
      {
        name: 'tryon',
        path: '/tryon',
        component: TryOnPage
      },
      {
        name: 'mytryon',
        path: '/mytryon',
        component: MyTryOnPage
      },
      {
        name: 'detail',
        path: '/detail/:type?/:id',
        component: DetailPage
      },
      {
        name: 'cloud',
        path: '/cloud',
        component: CloudPage
      }
    ]
  },
  {
    component: BasicLayout,
    name: 'cloud',
    path: '/cloud',
    children: [
      {
        name: 'cloud',
        path: '',
        component: CloudPage
      }
    ]
  }
];
