<script setup lang="ts">
import { useStyle3dStore } from '@repo/stores';
import { storeToRefs } from 'pinia';
import { ToolButton } from '@/components';
import { $t } from '@/locales';


const style3dStore = useStyle3dStore();
const { resolution } = storeToRefs(style3dStore);

const toggleResolution = () => {
  resolution.value = resolution.value === 'sd' ? 'hd' : 'sd';
};
</script>

<template>
  <div>
    <ToolButton @click="toggleResolution">
      {{ resolution === 'sd' ? $t('common.hd') : $t('common.sd') }}
    </ToolButton>
  </div>
</template>

<style module>
</style>

<style scoped>
:deep(.van-popover--dark .van-popover__content) {
  background-color: transparent !important;
}

</style>
