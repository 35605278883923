import type { App } from 'vue';

import {
  $t, loadLocalesMap,
  setupI18n as coreSetup,
  type SupportedLanguagesType
} from '@repo/locales';

import enLocale from 'vant/es/locale/lang/en-US';
import defaultLocale from 'vant/es/locale/lang/zh-CN';

import { ref } from 'vue';
import { usePreferredLanguages } from '@vueuse/core';

const vantLocale = ref(defaultLocale);

async function loadVantLocale(lang: SupportedLanguagesType) {
  switch (lang) {
    case 'zh': {
      vantLocale.value = defaultLocale;
      break;
    }
    case 'en': {
      vantLocale.value = enLocale;
      break;
    }
  }
}

const modules = import.meta.glob('./langs/*.json');

const localesMap = loadLocalesMap(modules);

async function loadMessages(lang: SupportedLanguagesType) {
  const [appLocaleMessages] = await Promise.all([
    localesMap[lang]?.(),
    loadThirdPartyMessage(lang)
  ]);
  return appLocaleMessages?.default;
}

async function loadThirdPartyMessage(lang: SupportedLanguagesType) {
  await Promise.all([loadVantLocale(lang)]);
}

async function setupI18n(app: App, lang: SupportedLanguagesType) {
  await coreSetup(app, {
    defaultLocale: lang,
    loadMessages
  });
}

const languages = usePreferredLanguages();

const getLocale = () => {
  let locale: SupportedLanguagesType = 'zh';

  if (languages.value[0]?.startsWith('en')) {
    locale = 'en';
  }

  return locale;
};

export { $t, setupI18n, getLocale };
