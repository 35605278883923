import { db } from './db.ts';
import type { TryOn } from './typing.ts';

function tokenize(tryon: Omit<TryOn, 'terms' | 'date'>) {
  const words = new Set<string>();
  if (tryon.id != null) {
    words.add(tryon.id.toString());
  }

  // tryon.memo && words.add(tryon.memo);
  // tryon.properties.forEach((prop) => {
  //   prop.value && words.add(prop.value);
  // });

  return Array.from(words);
}

export async function saveTryon(tryon: Omit<TryOn, 'terms' | 'date'>) {
  if (tryon.id === 0) {
    const temp: any = { ...tryon, terms: tokenize(tryon), date: Date.now() };
    delete temp.id;
    await db.add('tryons', temp);
  } else {
    await db.put('tryons', { ...tryon, terms: tokenize(tryon), date: Date.now() });
  }
}

export async function getTryons(count: number = 10, date?: [number, number]) {
  const queryRange = date && IDBKeyRange.bound(date[0], date[1]);
  const data = await db.getAllFromIndex('tryons', 'by-date', count, 'prev', queryRange);
  return data;
}

export async function searchTryons(keyword: string = '', count: number = 10, date?: [number, number]) {
  const queryRange = date && IDBKeyRange.bound(date[0], date[1]);
  const data = await db.searchByTerms('tryons', 'fulltext', keyword, count, queryRange);
  return data;
}

export async function deleteTryons(ids: number[]) {
  await Promise.all(ids.map(id => db.delete('tryons', id)));
}
