import { getAppConfig } from '@/utils/env.ts';
import {isAliyunOSSUrl, isStyle3dUrl, joinUrl} from '@repo/utils';
import axios, { type AxiosResponse } from 'axios';
import type { FabricShareInfo } from '@repo/style3d';
import type { FabricInfo } from '@/store/fabric.ts';
import { changeColor, reduceToOneColor } from "@repo/opencv";

const { OSS_BASE_URL, INDIVIDUAL_FABRIC_SHARES } = getAppConfig();
const fabricRootPath = `${OSS_BASE_URL}${INDIVIDUAL_FABRIC_SHARES}`;

export async function getFabricShareInfo(fabricId: string): Promise<FabricInfo> {
  const fabricRoot = joinUrl(fabricRootPath, fabricId);
  const infoResult: AxiosResponse<FabricShareInfo> = await axios.get(joinUrl(fabricRoot, 'info.json'));
  const info = infoResult.data;

  return {
    id: fabricId,
    info: info.metaData.info,
    enterpriseId: info.metaData.enterpriseID,
    enterpriseName: info.metaData.enterpriseName,
    thumb: info.info.main ? joinUrl(fabricRoot, 'fabric', info.info.main.name) : '',
    properties: info.metaData.property,
    styleProperties: info.metaData?.propertyStyle,
    carousels: info.doc?.map(item => ({ ...item, url: joinUrl(fabricRoot, item.name) })) || []
  };
}

export async function generateRibImage(mainColor: string): Promise<string> {
  return new Promise(resolve => {
    changeColor('//cloudknit-3d.oss-cn-shanghai.aliyuncs.com/resource/Rib/1X1.png', mainColor)
      .then(changedImageData => {
        const canvas = document.createElement('canvas');
        canvas.width = changedImageData.width;
        canvas.height = changedImageData.height;
        const ctx = canvas.getContext('2d');
        ctx!.putImageData(changedImageData, 0, 0);

        const dataUri = canvas.toDataURL('image/png');
        resolve(dataUri);
      });
  });
}

export async function generateFabricInfo(style3dFabricInfo: Pick<FabricInfo,'info'|'thumb'>, generateRibInfo: boolean = false) {
  const path = style3dFabricInfo.thumb;
  const { width, height } = style3dFabricInfo.info;
  const name = `${path}`;

  const mainInfo = {
    name,
    width,
    height,
    // 尺寸单位为mm，无需进行转换
    dpm: undefined
  };
  if (!generateRibInfo) {
    return {
      'info': {
        'main': {
          ...mainInfo
        }
      }
    };
  }

  const url = isAliyunOSSUrl(path) || isStyle3dUrl(path) ? `${path}?x-oss-process=image/resize,m_fixed,h_128,w_128,limit_0` : path;
  const mainColor = await reduceToOneColor(url);
  const newUrl = await generateRibImage(mainColor);
  const ribInfo = {
    'height': 99,
    'name': newUrl,
    'width': 110
  };

  const fabricInfo: Omit<FabricShareInfo, 'mask' | 'metaData' | 'doc' | 'enterprise_id'> = {
    'info': {
      'hem': {
        ...ribInfo
      },
      'collar': {
        ...ribInfo
      },
      'placket': {
        ...ribInfo
      },
      'main': {
        ...mainInfo
      }
    }
  };
  return fabricInfo;
}

