<script setup lang="ts">
import { Tabbar, TabbarItem } from 'vant';
import { computed } from 'vue';
import { $t } from '@/locales';
import { useRoute } from 'vue-router';

const route = useRoute();

const routeInfo = computed(() => {
  if (['fabric', 'detail'].includes(route.name as string)) {
    return {
      params: {
        id: route.params.id || 'default',
        type: route.params.type
      },
      query: route.query
    };
  } else {
    const lastRoute = JSON.parse(localStorage.getItem('lastRoute') || '{}');
    const { params, query } = lastRoute;
    return {
      params: {
        ...params
      },
      query: {
        ...query
      }
    };
  }

});

const fabricRoute = computed(() => ({
  name: 'fabric',
  params: {
    ...routeInfo.value.params
  },
  query: {
    ...routeInfo.value.query
  }
}));

</script>

<template>
  <Tabbar placeholder route safe-area-inset-bottom>
    <TabbarItem class="tabbar-item" replace :to="{...fabricRoute}">
      {{$t('common.return')}}
    </TabbarItem>
  </Tabbar>
</template>

<style scoped>
.tabbar-item {
  font-size: 16px;
}
</style>
