<script setup lang="ts">
import { computed } from 'vue';
import { Image as VantImage, type ImageProps } from 'vant';
import { isAliyunOSSUrl } from '@repo/utils';

const props = withDefaults(
  defineProps<Omit<Partial<ImageProps>, 'src' | 'width' | 'height'> & {
    src: string,
    width?: number,
    height?: number,
    highQuality?: boolean
  }>(),
  {
    highQuality: true
  });

const customProps = computed(() => {
  let src = props.src;
  if (props.width && props.height && isAliyunOSSUrl(props.src)) {
    const width = props.highQuality ? props.width * 2 : props.width;
    const height = props.highQuality ? props.height * 2 : props.height;

    src = `${props.src}?x-oss-process=image/resize,m_lfit,h_${height},w_${width},limit_0`;
  }
  return {
    ...props,
    src
  };

});
</script>

<template>
  <VantImage v-bind="customProps" />
</template>

<style scoped>

</style>
