<script setup lang="ts">
import { ref, toRaw } from 'vue';
import { Dialog, showToast } from 'vant';
import type { FavoriteItem } from '../typing.ts';
import { exportImage } from '../export-image.ts';
import { useLoading } from '@repo/components';
import { dateFormat } from '@repo/utils';
import { PopoverButtons, ToolButton, useAddressBook } from '@/components';
import { orderSample } from '@/api';
import { $t } from '@/locales';
import { SvgEllipsisHIcon, SvgShoppingCartIcon } from "@repo/icons";

const props = withDefaults(
  defineProps<{
    favoriteList: FavoriteItem[],
    selected: FavoriteItem[],
    enterpriseId?: string
  }>(),
  {
    favoriteList: () => [],
    selected: () => []
  });

const emit = defineEmits<{
  (e: 'selectAll'): void
  (e: 'delete'): void
}>();

const showModal = ref<boolean>(false);
const step = ref<1 | 2>(1);
const imageUrl = ref('');


const loading = useLoading();

const { getAddressBook, toggleModal: toggleAddressModal } = useAddressBook();
const onExportImg = async () => {
  const addressBook = getAddressBook();
  if (!addressBook) {
    showToast($t('message.set-contact-info'));
    setTimeout(() => {
      toggleAddressModal(true);
    }, 1000);
    return;
  }

  showModal.value = true;
  loading.setLoading(true);
  const list = (props.selected.length > 0)
    ? props.selected
    : props.favoriteList;
  imageUrl.value = await exportImage({
    ...toRaw(addressBook),
    date: dateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
  }, list);
  step.value = 2;
  loading.setLoading(false);
};

const onOrderSample = async () => {
  const addressBook = getAddressBook();
  if (!addressBook) {
    showToast($t('message.set-contact-info'));
    setTimeout(() => {
      toggleAddressModal(true);
    }, 1000);
    return;
  }

  const items = props.selected.map((item) => ({
    link: `https://oss.cloudknit.cn/fabric/${item.id}`,
    piece: item.num || 1
  }));
  try {
    await orderSample(
      {
        'user': {
          'name': addressBook.company || '',
          'contact': addressBook.contact || '',
          'phone': addressBook.phone || '',
          'address': addressBook.address || '',
          'enterpriseid': props.enterpriseId
        },
        'order': [
          ...items
        ]
      });
    showToast($t('message.order-success'));
  } catch (e: any) {
    showToast(e.message);
  }
};
</script>

<template>
  <PopoverButtons :dark="false" placement="left">
    <template #reference>
      <ToolButton>
        <SvgEllipsisHIcon style="width: 20px;height:20px;" />
      </ToolButton>
    </template>
    <ToolButton @click="emit('selectAll')">
      {{ $t('common.select-all') }}
    </ToolButton>
    <ToolButton @click="onExportImg">
      {{ $t('common.export') }}
    </ToolButton>
    <ToolButton @click="()=>toggleAddressModal(true)">
      {{ $t('common.address') }}
    </ToolButton>
    <ToolButton @click="emit('delete')" :disabled="!(props.selected.length>0)">
      {{ $t('common.delete') }}
    </ToolButton>
  </PopoverButtons>
  <ToolButton @click="onOrderSample" :disabled="!(props.selected.length>0)||!enterpriseId">
    <SvgShoppingCartIcon style="width: 20px;height:20px;" />
  </ToolButton>
  <div>
    <Dialog v-model:show="showModal" :show-confirm-button="false" close-on-click-overlay>
      <div :class="$style.dialogContent">
        <img :src="imageUrl" alt="" :class="$style.imgPreview">
      </div>
    </Dialog>
  </div>
</template>

<style module>
.dialogContent {
  padding: 5px;
  display: flex;
  justify-content: center;
}

.imgPreview {
  padding: 10px;
  width: 90%;
}
</style>
