<script setup lang="ts">
import { Image as VanImage, showImagePreview } from 'vant';
import type { TryOnItem } from '../typing.ts';

const props = defineProps<TryOnItem>();

</script>

<template>
  <div class="image-card" v-if="props.task_status==='SUCCEEDED'">
    <van-image
      :src="props.imageUrl"
      width="100%"
      lazy-load
      object-fit="scale-down"
      @click="showImagePreview([props.imageUrl || ''])"
    >
      <template v-slot:error>
      </template>
    </van-image>
  </div>
  <div
    class="image-card pending"
    v-else-if="props.task_status==='PENDING' || props.task_status==='RUNNING'"
  >
    <span>正在生成，预计10-20秒，请等待</span>
  </div>
  <div class="image-card pending" v-else>
    <span>糟糕~~生成失败了</span>
  </div>
</template>

<style scoped>
.image-card {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 7px;
  align-items: center;
  justify-content: space-around;
}

.image-card.pending {
  height: 200px;
}

</style>
