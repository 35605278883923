<script setup lang="ts">
import { Tabbar, TabbarItem } from 'vant';
import { computed, ref } from 'vue';
import { $t } from '@/locales';
import { useRoute } from 'vue-router';
import Scan from './Scan.vue';

const active = ref(0);

const route = useRoute();

const routeInfo = computed(() => {
  if (['fabric', 'detail'].includes(route.name as string)) {
    return {
      params: {
        id: route.params.id || 'default',
        type: route.params.type
      },
      query: route.query
    };
  } else {
    const lastRoute = JSON.parse(localStorage.getItem('lastRoute') || '{}');
    const { params, query } = lastRoute;
    return {
      params: {
        ...params
      },
      query: {
        ...query
      }
    };
  }

});

const detailRoute = computed(() => ({
  name: 'detail',
  params: {
    ...routeInfo.value.params
  },
  query: {
    ...routeInfo.value.query
  }
}));

const fabricRoute = computed(() => ({
  name: 'fabric',
  params: {
    ...routeInfo.value.params
  },
  query: {
    ...routeInfo.value.query
  }
}));

const showScan = computed(() => {
  return ['fabric', 'detail', 'favorite'].includes(route.name as string);
});
</script>

<template>
  <Tabbar v-model="active" placeholder route safe-area-inset-bottom>
    <TabbarItem class="tabbar-item" v-if="showScan">
      <Scan />
    </TabbarItem>
    <TabbarItem class="tabbar-item" replace :to="{...detailRoute}">
      {{ $t('common.details') }}
    </TabbarItem>
    <TabbarItem class="tabbar-item" replace :to="{...fabricRoute}">
      3D
    </TabbarItem>
    <TabbarItem class="tabbar-item" replace :to="{name:'favorite'}">
      {{ $t('tabbar.favorites') }}
    </TabbarItem>
  </Tabbar>
</template>

<style scoped>
.tabbar-item {
  font-size: 16px;
}
</style>
