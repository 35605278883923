<script setup lang="ts">
import { ActionSheet } from 'vant';
import type { ColorPalette } from '@/store/fabric.ts';
import { ColorPicker } from '@/components/color-picker';
import { SvgColorPalette } from '@repo/icons';

const defaultPalette = [
  {
    colorNo: '1',
    color: `rgb(169, 216, 246)`
  },
  {
    colorNo: '2',
    color: `rgb(206, 226, 224)`
  },
  {
    colorNo: '3',
    color: `rgb(249, 240, 235)`
  },
  {
    colorNo: '4',
    color: `rgb(214, 159, 152)`
  },
  {
    colorNo: '5',
    color: `rgb(183, 171, 185)`
  }
];

const show = defineModel<boolean>('show');

const props = defineProps<{ title: string, palette: ColorPalette[] | undefined }>();

const emit = defineEmits<{ change: [value: string] }>();

const onClickColor = (color: string) => {
  emit('change', color);
};
</script>

<template>
  <ActionSheet v-model:show="show" :title="props.title" :overlay-style="{background:'none'}">
    <div :class="$style.content">
      <div
        v-for="p of props.palette||defaultPalette"
        :key="p.colorNo"
        :style="{ backgroundColor: p.color }"
        style="width: 30px;height:30px;"
        @click="()=>onClickColor(p.color)"
      />
      <ColorPicker style="width: 30px;height:30px;" @confirm="onClickColor">
        <SvgColorPalette style="width: 100%; height: 100%;" />
      </ColorPicker>
    </div>
  </ActionSheet>
</template>

<style module>
.content {
  padding: 10px 10px 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
</style>
