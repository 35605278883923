<script setup lang="ts">
import { FavoriteEdit, ToolButton } from '@/components';
import { useFabricStore } from '@/store/fabric.ts';
import { SvgStarOutlineIcon } from '@repo/icons';

const fabricStore = useFabricStore();
</script>

<template>
  <FavoriteEdit v-bind="fabricStore.fabricInfo">
    <ToolButton>
      <SvgStarOutlineIcon style="width: 20px;height:20px;" />
    </ToolButton>
  </FavoriteEdit>
</template>

<style module>
</style>
