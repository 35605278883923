<script setup lang="ts">
import { Button, DropdownItem, DropdownMenu, List, Search } from 'vant';
import { computed, onMounted, ref } from 'vue';
import { debounce } from 'lodash-es';

import { $t } from '@/locales';
import { OfficialStyleFilter, OfficialStyleList, useOfficialStyle } from './components/official-style';
import { FavoriteFabricList, FavoriteFabricFilter, useFavoriteFabric } from './components/favorite-fabric';
import { Tabbar } from './components/tabbar';

const searchTerms = ref<string>('');

const search = computed(() => {
  return searchTerms.value.trim().split(' ').filter(Boolean);
});

const onSearch = async () => {
  localStorage.setItem('lastCloudPageSearch', searchTerms.value);
  console.log('onSearch');
  await debouncedReload();
};

type CloudType = 'official-style' | 'favorite-fabric';
const type = ref<CloudType>('official-style');

const typeOptions = [
  { text: '官方廓形库', value: 'official-style' },
  { text: '面料收藏', value: 'favorite-fabric' }
];

const page = ref(1);

const loading = ref(false);
const finished = ref(false);

onMounted(async () => {
  const lastCloudPageSearch = localStorage.getItem('lastCloudPageSearch');
  searchTerms.value = lastCloudPageSearch || '';

  const lastCloudPageType = localStorage.getItem('lastCloudPageType');
  type.value = lastCloudPageType as CloudType || 'official-style';

  await debouncedReload();
});

const onTypeChange = async () => {
  localStorage.setItem('lastCloudPageType', type.value);
  await debouncedReload();
};

const { loadData: loadOfficialStyleData } = useOfficialStyle();
const { loadData: loadFavoriteFabricData } = useFavoriteFabric();

async function reload() {
  page.value = 1;
  loading.value = true;
  finished.value = false;
  await loadMore(true);
}

const loadData = async (init: boolean, page: number, search: string[]) => {
  if (type.value === 'official-style') {
    return await loadOfficialStyleData(init, page, search);
  } else {
    return await loadFavoriteFabricData(init, page, search);
  }
};

const debouncedReload = debounce(reload, 100);

async function loadMore(init = false) {
  const data = await loadData(init, page.value, search.value);
  loading.value = false;
  page.value += 1;
  if (data.length < 10) {
    finished.value = true;
  }
}
</script>

<template>
  <div :class="$style.cloudPage">
    <div :class="$style.header">
      <Search
        :class="$style.headerRow"
        v-model="searchTerms"
        show-action
      >
        <template #action>
          <Button @click="onSearch" size="small" :disabled="loading">{{ $t('common.search') }}</Button>
        </template>
      </Search>
      <DropdownMenu :class="$style.headerRow">
        <DropdownItem v-model="type" :options="typeOptions" @change="onTypeChange" />
        <OfficialStyleFilter @change="debouncedReload" v-if="type==='official-style'" />
        <FavoriteFabricFilter @change="debouncedReload" v-else-if="type==='favorite-fabric'" />
      </DropdownMenu>
    </div>
    <div :class="$style.content">
      <List
        :class="$style.listContainer"
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="loadMore"
        :immediate-check="false"
      >
        <div :class="$style.cardList">
          <OfficialStyleList v-if="type==='official-style'" />
          <FavoriteFabricList v-else-if="type==='favorite-fabric'" />
        </div>
      </List>
    </div>
    <Tabbar/>
  </div>
</template>

<style module>
.cloudPage {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
}

.headerRow {
  width: 100%;
}

.content {
  flex: 1;
  width: 100%;
  border-top: solid 1px #d0d0d0;
  overflow: hidden;
}

.listContainer {
  height: 100%;
  overflow-y: auto;
}

.cardList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(230px, auto) 1fr;
}

.cardList:empty {
  height: 0;
}
</style>

<style scoped>
:deep(.van-dropdown-menu__bar) {
  box-shadow: unset;
}
</style>
