<script setup lang="ts">
import { Image } from "vant";
import type { Project } from "@/api";
import { useRoute, useRouter } from "vue-router";
import { useParamStore } from "@/store/param.ts";

const props = defineProps<{ projects: Project[] }>()

const router = useRouter();
const route = useRoute();
const { refreshLoadResourceByRoute } = useParamStore();

const onClick = async (project: Project) => {
  if (project.type === 'style3d') {
    await router.replace({ params: { ...route.params }, query: { sco: project.scoPath, scoId: project.scoId } });
  } else {
    await router.replace({ params: { ...route.params }, query: { scoId: project.scoId } });
  }
  await refreshLoadResourceByRoute();
};
</script>

<template>
  <div
      v-for="(item) in props.projects"
      :key="item.id"
  >
    <Image
        width="75"
        height="75"
        :src="item.thumb"
        fit="scale-down"
        @click="()=>onClick(item)"
    />
  </div>
</template>

<style scoped>

</style>