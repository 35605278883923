import axios from 'axios';
import type { Style3dFabric } from './typing.ts';
import { joinUrl } from '@repo/utils';
import { getAppConfig } from '@/utils';
import { getLocale } from '@/locales';

export * from './typing.ts';

export async function getStyle3dFabricApi(fabricId: string): Promise<Style3dFabric> {
  const { URL_PARSE_URL } = getAppConfig();
  const currentLocale = getLocale();
  const locale = currentLocale === 'zh' ? 'zh-CN' : 'en';
  
  const resp = await axios.get(joinUrl(URL_PARSE_URL, `/style3d/resource/${fabricId}?locale=${locale}`));
  return resp.data.data as Style3dFabric;
}
