<script setup lang="ts">
import { computed, ref } from 'vue';
import { QRScanModal } from '@repo/components';
import { isWeixinBrowser } from '@repo/utils';
import { until } from '@vueuse/core';
import { useWx } from '@repo/wx';
import { showNotify } from 'vant';
import { $t } from '@/locales';

const props = defineProps<{
  loading?: boolean;
  validator: (text: string) => boolean;
  callback: (text: string) => Promise<void>;
}>();

const isWechat = isWeixinBrowser();

const { wxScan, wxInstance } = useWx();

const processing = ref(false);

const loading = computed(() => !wxInstance.isReady.value || processing.value || props.loading);

const onScan = async () => {
  if (loading.value) {
    return;
  }

  try {
    await until(wxInstance.isReady).toBe(true);

    await wxScan({
      onSuccess: async function(res: any) {
        const valid = props.validator(res.resultStr);
        if (valid) {
          processing.value = true;
          try {
            await props.callback(res.resultStr);
          } finally {
            processing.value = false;
          }
        } else {
          showNotify({ type: 'warning', message: $t('message.verify-fail') });
        }
      },
      onFailure: function(err: any) {
        showNotify({ type: 'warning', message: JSON.stringify(err) });
      }
    });
  } catch (e) {
    showNotify({ type: 'warning', message: JSON.stringify(e) });
  }
};
</script>

<template>
  <div>
    <span v-if="isWechat" @click="onScan">
       <slot name="scan-icon" :loading="loading"></slot>
    </span>
    <template v-else>
      <QRScanModal @change="props.callback" :validator="validator">
        <slot name="scan-icon" :loading="false">
        </slot>
      </QRScanModal>
    </template>
  </div>
</template>
<style scoped>
</style>
