<script setup lang="ts">
import { ref } from 'vue';
import { useRecentListStore } from '@/store/recent-list';
import { useFavoriteListStore } from "@/store/favorite-list";
import { useRouter } from 'vue-router';
import { SvgPlusOutlined, SvgSquareTransferOutline } from '@repo/icons';
import RecentProjects from "./recent-projects.vue";
import FabricList from "./fabric-list.vue";

const recentListStore = useRecentListStore();
const favoriteListStore = useFavoriteListStore();

type Mode = 'project' | 'fabric';
const mode = ref<Mode>(localStorage.getItem('recentListMode') as Mode || 'project')

const router = useRouter();
const onClickCloud = async () => {
  await router.replace({ name: 'cloud' });
};

const toggle = () => {
  mode.value = mode.value === 'project' ? 'fabric' : 'project';
  localStorage.setItem('recentListMode', mode.value);
};
</script>
<template>
  <div :class="$style.shareListContainer">
    <div :class="$style.extra">
      <SvgPlusOutlined style="width:30px;height:30px;color: #eee;" @click="onClickCloud" />
    </div>
    <div :class="$style.recentList">
      <RecentProjects
          :projects="recentListStore.recentProjects"
          v-if="mode==='project'"
      />
      <FabricList
          :fabrics="favoriteListStore.recentFavorites"
          v-else
      />
    </div>
    <div :class="$style.extra">
      <SvgSquareTransferOutline style="width:30px;height:30px;color: #eee;" @click="toggle" />
    </div>
  </div>
</template>
<style module>
.shareListContainer {
  padding: 0px 0px 0px;
  display: flex;
}

.recentList {
  flex: 1;
  display: flex;
  overflow: auto;
}

.extra {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
</style>
