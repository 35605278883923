import OSS from 'ali-oss';
import axios from 'axios';
import { getAppConfig } from '@/utils';
import { joinUrl } from '@repo/utils';

interface Credentials {
  'AccessKeyId': string;
  'AccessKeySecret': string;
  'SecurityToken': string;
  'Expiration': string;
}

let credentials: Credentials | null = null;

async function getStsToken(): Promise<Credentials> {
  const { URL_PARSE_URL } = getAppConfig();

  if (!credentials) {
    const storedCredentials = localStorage.getItem('ossCredentials');
    if (storedCredentials) {
      credentials = JSON.parse(storedCredentials);
    }
  }

  if (!credentials || isCredentialsExpired(credentials)) {
    const response = await axios.get(joinUrl(URL_PARSE_URL, `/oss/sts_token`));
    if (response.status !== 200) {
      throw new Error(
        `get STS token fail : ${response.status} ${response.statusText}`
      );
    }
    credentials = response.data;
    
    localStorage.setItem('ossCredentials', JSON.stringify(credentials));
  }

  if (!credentials) {
    throw new Error('Failed to obtain valid credentials');
  }

  return credentials;
}

/**
 * Upload file via sts token
 * @param key /individual_fabric_shares/*, aitryon/*, individual_shares/*
 * @param file
 * @return url
 */
export async function uploadFileViaStsToken(key: string, file: File): Promise<string> {
  const credentials = await getStsToken();
  const client = new OSS({
    bucket: 'cloudknit-3d',
    region: 'oss-cn-shanghai',
    accessKeyId: credentials.AccessKeyId,
    accessKeySecret: credentials.AccessKeySecret,
    stsToken: credentials.SecurityToken
  });

  try {
    const result = await client.put(key, file);
    return result.url;
  } catch (error) {
    return Promise.reject('Oss upload fail');
  }
}

function isCredentialsExpired(credentials: Credentials | null) {
  if (!credentials) {
    return true;
  }
  const expireDate = new Date(credentials.Expiration);
  const now = new Date();
  // 如果有效期不足一分钟，视为过期。
  return expireDate.getTime() - now.getTime() <= 60000;
}


