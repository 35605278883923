import { getAppConfig } from '@/utils/env';
import { defineStore, storeToRefs, useSelectionStore } from '@repo/stores';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { until } from '@vueuse/core';
import {
  type FabricShareInfo,
  type Relation,
  useStyle3d,
  mergeShareFabricInfo,
  type Shape,
  type DPM
} from '@repo/style3d';
import axios, { type AxiosResponse } from 'axios';
import { delay, joinUrl } from '@repo/utils';
import { getStyle3dFabricApi } from '@/api';
import { useLoading } from '@repo/components';
import { useStyle3dStore, useRelationStore } from '@repo/stores';
import { useRecentListStore } from '@/store/recent-list.ts';
import { useFabricStore } from '@/store/fabric.ts';
import { generateFabricInfo, getFabricShareInfo } from '@/utils';

const { OSS_BASE_URL, INDIVIDUAL_SHARES, INDIVIDUAL_FABRIC_SHARES } = getAppConfig();
const projectRootPath = `${OSS_BASE_URL}${INDIVIDUAL_SHARES}`;
const fabricRootPath = `${OSS_BASE_URL}${INDIVIDUAL_FABRIC_SHARES}`;

const need3DResourceRoutes = ['project', 'fabric', 'style3d', 'detail'];

interface CloudknitScoResource {
  type: 'cloudknit';
  id: string;
}

interface Style3dScoResource {
  type: 'style3d';
  id: string;
  url: string;
}

type ScoResource = CloudknitScoResource | Style3dScoResource;

interface CloudknitFabricResource {
  type: 'cloudknit';
  id: string;
}

interface Style3dFabricResource {
  type: 'style3d';
  id: string;
}

type FabricResource = CloudknitFabricResource | Style3dFabricResource;

interface ResourceParam {
  scoResource: ScoResource;
  fabricResource: FabricResource;
  show3d: boolean;
}

function removeProtocol(url: string): string {
  return url.replace(/^https?:/, '');
}

export const useParamStore = defineStore('param', () => {
  const route = useRoute();
  const loadingSpin = useLoading();

  const resourceParam = ref<ResourceParam>({
    scoResource: {
      type: 'cloudknit',
      id: 'fabric_hanger'
    },
    fabricResource: {
      type: 'cloudknit',
      id: 'default'
    },
    show3d: false
  });

  const patternTypeMap = ref<Map<string, string>>(new Map<string, string>());

  const { loadSco, loadFabric, resetCameraViewState, getScreenshot, getStyle3dSDKInstance } = useStyle3d();

  const style3dStore = useStyle3dStore();
  const { loaded: sdkLoaded, ready: sdkReady, resolution } = storeToRefs(style3dStore);

  const relationStore = useRelationStore();

  async function loadScoResource() {
    console.time('loadScoResource');
    await until(sdkLoaded).toBe(true);

    const scoResource = resourceParam.value.scoResource;
    if (scoResource.type === 'style3d') {
      {
        await axios.get(decodeURIComponent(scoResource.url)).then((response) => {
          const data = response.data;

          patternTypeMap.value.clear();
          Object.values(data.material).map((item: any) => {
            patternTypeMap.value.set(item.id, item.type);
          });
        });
      }
      await loadSco(decodeURIComponent(scoResource.url));
    } else {
      await loadSco(joinUrl(projectRootPath, scoResource.id, '/sco/sco.json'));
    }
    console.timeEnd('loadScoResource');
  }

  const fabricStore = useFabricStore();

  async function loadFabricResource() {
    console.time('loadFabricResource');
    let shapeList: Shape[] = [];

    const show3d = resourceParam.value.show3d;
    const scoResource = resourceParam.value.scoResource;
    const fabricResource = resourceParam.value.fabricResource;

    const fabricRoot = joinUrl(fabricRootPath, fabricResource.id);
    const infoResult: AxiosResponse<FabricShareInfo> = await axios.get(joinUrl(fabricRoot, 'info.json'));
    const fabricShareInfo = infoResult.data;
    if (show3d) {
      await until(sdkReady).toBe(true);
      shapeList = await handle3DResource(scoResource, fabricShareInfo);
      shapeList = await loadFabric(shapeList, fabricRoot, resolution.value);
      relationStore.setShape(shapeList);
    }

    await reloadFabricInfo();
    console.timeEnd('loadFabricResource');
  }

  async function loadStyle3dResource() {
    const show3d = resourceParam.value.show3d;
    const scoResource = resourceParam.value.scoResource;

    const style3dFabricInfo = await reloadStyle3dFabricInfo();
    if (!show3d) {
      return;
    }

    const generateRibInfo = scoResource.type !== 'style3d';
    const fabricInfo = await generateFabricInfo(style3dFabricInfo, generateRibInfo);
    await until(sdkReady).toBe(true);
    let shapeList = await handle3DResource(scoResource, fabricInfo);
    shapeList = await loadFabric(shapeList, '', resolution.value);
    relationStore.setShape(shapeList);
  }

  async function handle3DResource(scoResource: ScoResource, fabricInfo: Pick<FabricShareInfo, 'info'>): Promise<Shape[]> {
    let shapeList: Shape[] = [];

    if (scoResource.type === 'style3d') {
      shapeList = await mapStyle3dMaterialList(fabricInfo);
    } else {
      const projectRoot = joinUrl(projectRootPath, scoResource.id);
      const jsonResult: AxiosResponse<Relation> = await axios.get(joinUrl(projectRoot, '/relation.json'));
      const relation = jsonResult.data;
      relationStore.setRelation(relation);
      if (relation.shape.length > 0) {
        shapeList = mergeShareFabricInfo(relation.shape, projectRoot, fabricInfo);
      } else {
        shapeList = await mapStyle3dMaterialList(fabricInfo);
      }
    }
    return shapeList;
  }

  async function mapStyle3dMaterialList(fabricInfo: Pick<FabricShareInfo, 'info'>) {
    if (!fabricInfo.info.main) {
      console.error('fabricInfo.info.main is empty');
      return [];
    }
    const { dpm, width, height, name } = fabricInfo.info.main;

    const style3dSDK = getStyle3dSDKInstance();
    let shapeList = await style3dSDK!.getMaterialList();
    shapeList = filterAndMapShapes(shapeList, name, width, height, dpm);
    return shapeList;
  }

  function filterAndMapShapes(shapeList: Shape[], name: string, width?: number, height?: number, dpm?: DPM) {
    return shapeList
      .filter(item => ['pattern', 'fabric', ''].includes(patternTypeMap.value.get(`${item.id}`) || ''))
      .map(item => ({
        ...item,
        'piece': item.name,
        width,
        height,
        dpm,
        name
      }));
  }

  const selectionStore = useSelectionStore();

  async function reload() {
    loadingSpin.setLoading(true);
    console.time('style3d onReady');

    const show3d = resourceParam.value.show3d;
    const fabricResource = resourceParam.value.fabricResource;

    show3d && await loadScoResource();
    if (fabricResource.type === 'style3d') {
      await loadStyle3dResource();
    } else {
      await loadFabricResource();
    }
    selectionStore.setSelectedFabric('');
    loadingSpin.setLoading(false);

    if (show3d) {
      await delay(100);
      await resetCameraViewState();
    }
  }

  watch(() => route.name, async () => {
    if (need3DResourceRoutes.includes(route.name as string)) {
      await refreshLoadResourceByRoute();
    }
  }, {
    immediate: true
  });

  async function refreshLoadResourceByRoute() {
    const { type, id } = route.params as { [key: string]: string };
    const { scoId, sco: scoPath } = route.query as { [key: string]: string };

    const show3d = route.name === 'fabric';
    const fabricResource: FabricResource = {
      type: type === 'style3d' ? 'style3d' : 'cloudknit',
      id: id as string
    };

    const scoResource: ScoResource = {
      type: scoPath ? 'style3d' : 'cloudknit',
      id: scoId || 'fabric_hanger',
      url: scoPath ? removeProtocol(scoPath) : ''
    };

    resourceParam.value = {
      scoResource,
      fabricResource,
      show3d
    };

    await reload();
    if (scoId || scoPath) {
      await addToRecentList();
    }
  }

  async function reloadFabricInfo() {
    const fabricId = resourceParam.value.fabricResource.id;

    const fabricInfo = await getFabricShareInfo(fabricId);
    fabricStore.setFabricInfo(fabricInfo);

    return fabricInfo;
  }

  async function reloadStyle3dFabricInfo() {
    const style3dFabricId = resourceParam.value.fabricResource.id;

    const style3dFabric = await getStyle3dFabricApi(style3dFabricId);
    const path = style3dFabric.data.xhr_path || style3dFabric.data.thumb_path;

    const keyMap = style3dFabric.create_info.fields.map(item => ({ name: item.name, key: item.key }));
    const fabricProperty = keyMap
      .map((item) => ({ name: item.name, key: item.key, value: style3dFabric.data[item.key] }))
      .filter(item => item.value);

    const carousels = style3dFabric.data.all_thumbs.map((item: any) => {
      const url = item.thumb_path.split('?')[0];
      const urlParts = url.split('/');
      const decodedFileName = decodeURIComponent(urlParts[urlParts.length - 1]);
      const fileType = decodedFileName?.split('.')?.pop()?.toLowerCase() || '';

      const fileCategory = ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileType) ? 'picture' :
        ['mp4', 'avi', 'mov', 'wmv'].includes(fileType) ? 'video' : 'unknown';

      return {
        name: decodedFileName,
        type: fileCategory,
        url
      };
    });

    const fabricInfo = {
      id: `style3d-${style3dFabricId}`,
      info: {
        width: style3dFabric.data.width,
        height: style3dFabric.data.height
      },
      carousels,
      enterpriseId: '',
      enterpriseName: '',
      thumb: path,
      properties: fabricProperty
    };
    fabricStore.setFabricInfo(fabricInfo);

    return fabricInfo;
  }

  const recentListStore = useRecentListStore();

  async function addToRecentList() {
    const thumb = await getScreenshot();

    const scoResource = resourceParam.value.scoResource;
    const id = scoResource.type === 'style3d' ? `style3d-${scoResource.id}` : scoResource.id;
    if (scoResource.type === 'style3d') {
      await recentListStore.addRecentProject({
        id,
        scoId: scoResource.id,
        scoPath: scoResource.url,
        type: scoResource.type,
        thumb
      });
    } else {
      await recentListStore.addRecentProject({
        id,
        scoId: scoResource.id,
        type: scoResource.type,
        thumb
      });
    }
  }

  return { resourceParam, refreshLoadResourceByRoute };
});
