<script setup lang="ts">
</script>

<template>
  <div :class="$style.container">
    <div :class="$style.mainContent">
      <RouterView />
    </div>
  </div>
</template>

<style module>
.container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.mainContent {
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>
