import { ref } from 'vue';
import type { UploadFileInfo } from '@/views/tryon/typing.ts';

export const modelImageList = ref<UploadFileInfo[]>([
  {
    id: '1',
    name: 'model1.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/model/1.png'
  },
  {
    id: '2',
    name: 'model2.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/model/2.png'
  },
  {
    id: '3',
    name: 'model3.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/model/3.png'
  },
  {
    id: '5',
    name: 'model5.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/model/5.png'
  },
  {
    id: '6',
    name: 'model6.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/model/6.png'
  },
  {
    id: '7',
    name: 'model7.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/model/7.png'
  },
  {
    id: '8',
    name: 'model8.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/model/8.png'
  },
  {
    id: '9',
    name: 'model9.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/model/9.png'
  },
  {
    id: '10',
    name: 'model10.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/model/10.png'
  },
  {
    id: '11',
    name: 'model11.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/model/11.png'
  },
  {
    id: '12',
    name: 'model12.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/model/12.png'
  },
  {
    id: '13',
    name: 'model13.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/model/13.png'
  },
  {
    id: '14',
    name: 'model14.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/model/14.png'
  },
  {
    id: '15',
    name: 'model15.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/model/15.png'
  },
  {
    id: '16',
    name: 'model16.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/model/16.png'
  },
  {
    id: '17',
    name: 'model17.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/model/17.png'
  },
  {
    id: '18',
    name: 'model18.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/model/18.png'
  },
  {
    id: '19',
    name: 'model19.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/model/19.png'
  },
  {
    id: '20',
    name: 'model20.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/model/20.png'
  }
]);

export const pantsImageList = ref<UploadFileInfo[]>([
  {
    id: '1',
    name: 'pants1.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/pants1.jpg'
  },
  {
    id: '2',
    name: 'pants2.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/pants2.jpg'
  },
  {
    id: '3',
    name: 'pants3.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/pants3.jpg'
  },
  {
    id: '4',
    name: 'pants4.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/pants4.jpg'
  },
  {
    id: '5',
    name: 'pants5.png',
    status: 'finished',
    url: 'https://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/aitryon/assets/pants5.jpg'
  }
]);
