import { computed } from 'vue';
import { useRelationStore } from '@repo/stores';
import { defineStore } from '@repo/stores';

export const useShareStore = defineStore('share', () => {
  const relationStore = useRelationStore();

  const logoUrl = computed(() => relationStore.relation?.metadata?.logo);

  return {
    logoUrl
  };
});
