<script setup lang="ts">
import { Image } from '@/components';
import { useFavoriteFabric } from './use-favorite-fabric.ts';
import { useRouter } from 'vue-router';
import type { FavoriteItem } from '@/views/favorite/typing.ts';
import { useParamStore } from '@/store/param.ts';

const { results } = useFavoriteFabric();

const router = useRouter();
const { refreshLoadResourceByRoute } = useParamStore();

const onClick = async (item: FavoriteItem) => {
  const lastRoute = JSON.parse(localStorage.getItem('lastRoute') || '{}');
  await router.replace({
    name: 'fabric', query: {
      ...lastRoute.query
    },
    params: {
      type: item.resourceType,
      id: item.id
    }
  });
  await refreshLoadResourceByRoute();
};

</script>

<template>
  <div
    :class="$style.card"
    v-for="item of results"
    :key="item.id"
    @click="onClick(item)"
  >
    <Image
      :src="item.thumb||''"
      lazy-load
      :height="200"
      fit="scale-down"
    />
    <div :class="$style.name">
      {{ item.info?.name || item.id }}
    </div>
  </div>
</template>

<style module>
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.name {
  padding: 0 10px;
}
</style>

