<script setup lang="ts">
import { computed, ref } from 'vue';
import { ConfigProvider, Popover, type PopoverPlacement } from 'vant';

const props = withDefaults(defineProps<{ dark: boolean, placement: PopoverPlacement }>(), {
  dark: false,
  placement: 'bottom'
});

const themeVars = computed(() => {
  return props.dark ? {
    'button-default-color': '#fff',
    'button-default-background': 'rgba(0, 0, 0, .4)',
    'button-default-border-color': 'rgba(0, 0, 0, 0)'
  } : {
    'button-default-border-color': '#dcdee0'
  };
});

const showPopover = ref<boolean>(false);

</script>

<template>
  <Popover v-model:show="showPopover" :placement="props.placement" :show-arrow="false" :theme="props.dark?'dark':''">
    <template #reference>
      <slot name="reference"></slot>
    </template>
    <ConfigProvider :theme-vars="themeVars">
      <div :class="$style.tools">
        <slot></slot>
      </div>
    </ConfigProvider>
  </Popover>
</template>

<style module>
.tools {
  padding: 2px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  background-color: transparent;
}
</style>

<style scoped>
.van-popover--dark :deep(.van-popover__content) {
  background-color: transparent !important;
}
</style>
