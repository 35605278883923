import {defineStore} from '@repo/stores';
import {ref, watch} from 'vue';
import {type Favorite, getFavorites, saveFavorite} from '@/api';

export const useFavoriteListStore = defineStore('favorite-list', () => {
  const recentUpdate = ref<number>();
  const recentFavorites = ref<Favorite[]>([]);

  const addFavorite = async (favorite: Omit<Favorite, 'terms' | 'date'>) => {
    await saveFavorite(favorite);
    recentUpdate.value = Date.now();
  }
  watch(() => recentUpdate.value, async () => {
    recentFavorites.value = await getFavorites(30);
  }, {
    immediate: true
  });

  return {recentFavorites, recentUpdate, addFavorite};
});
