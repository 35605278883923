import { onMounted, onUnmounted, ref, type Ref } from 'vue';

// 创建一个组合式函数
export function useTimer(interval = 1000) {
  const timerCallback: Ref<(() => void) | null> = ref(() => { });

  // 设置一个定时器，并在组件卸载时清理
  let timerId: number | null = null;

  onMounted(() => {
    timerId = setInterval(() => {
      if (timerCallback.value) {
        timerCallback.value();
      }
    }, interval);
  });

  onUnmounted(() => {
    if (timerId) {
      clearInterval(timerId);
      timerCallback.value = null;
    }
  });

  // 返回 callback
  return { timerCallback };
}