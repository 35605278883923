<script setup lang="ts">
import { ConfigProvider } from 'vant';
import { computed } from 'vue';

const props = withDefaults(defineProps<{ dark?: boolean, bottom?: number | string }>(), {
  dark: false,
  bottom: '5px'
});

const themeVars = computed(() => {
  const common = {
    'button-default-background': 'rgb(153, 153, 153)',
    'button--disabled': 'rgb(204, 204, 204)',
    'button-small-height': '38px',
    'button-small-font-size': '14px'
  };
  return props.dark ? {
    ...common,
    'button-default-color': '#fff',
    'button-default-border-color': 'rgba(0, 0, 0, 0)'
  } : {
    ...common,
    'button-default-color': '#fff',
    'button-default-border-color': '#dcdee0'
  };
});
</script>

<template>
  <ConfigProvider :theme-vars="themeVars">
    <div :class="$style.container" :style="{}">
      <slot></slot>
    </div>
  </ConfigProvider>
</template>

<style module>
.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
  bottom: v-bind(bottom);
  right: 10px;
  justify-content: space-around;
  font-size: 1.2em;
  padding-bottom: 10px;
}
</style>
