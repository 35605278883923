<script setup lang="ts">
import { ref } from 'vue';
import { Button, Popup } from 'vant';
import { $t } from '@/locales';

import { Vue3ColorPicker } from '@cyhnkckali/vue3-color-picker';
import '@cyhnkckali/vue3-color-picker/dist/style.css';

const emit = defineEmits<{
  confirm: [value: string]
}>();

const show = ref(false);
const value = ref<string>('rgb(0,0,0)');
const toggle = () => {
  show.value = !show.value;
};

const onConfirm = () => {
  show.value = false;
  emit('confirm', value.value);
};
</script>

<template>
  <div>
    <div @click="toggle" style="width: 100%; height: 100%;">
      <slot>
        <div
          :style="{ backgroundColor: value }"
          style="width: 100%; height: 100%;"
        />
      </slot>
    </div>
    <Popup v-model:show="show">
      <Vue3ColorPicker
        v-model="value"
        mode="solid"
        :showInputSet="false"
        :showPickerMode="false"
        :showColorList="false"
        :showEyeDrop="false"
        :showInputMenu="false"
        type="RGB"
      />
      <div style="padding:10px;text-align:right;">
        <Button size="small" type="primary" @click="onConfirm">
          {{ $t('common.confirm') }}
        </Button>
      </div>
    </Popup>
  </div>
</template>

<style scoped>

</style>
