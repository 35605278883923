import axios from 'axios';

export interface TryonRequest {
  phone: string;
  fabricid: string;
  enterpriseid: string;
  model: 'aitryon' | string;
  input: {
    top_garment_url: string;
    bottom_garment_url?: string;
    person_image_url: string;
  };
}

export interface TryonTaskResponse {
  status: 'FAILED' | 'SUCCEED' | string;
  message: string;
  output: {
    task_id: string;
    task_status: string;
  };
  request_id: string;
}

export interface TryonStatusResponse {
  status: 'FAILED' | 'SUCCEED' | string;
  message: string;
  output: {
    task_id: string;
    task_status: string;
    submit_time: string;
    scheduled_time: string;
    end_time: string;
    image_url?: string;
  };
  usage: {
    image_count: number;
  };
  request_id: string;
}

export async function createTryonTask(reqParam: TryonRequest): Promise<TryonTaskResponse> {
  const response = await axios.post('https://aimodelproxy-yjtvjpfhep.cn-hangzhou.fcapp.run', reqParam, { validateStatus: () => true });
  if (response.status !== 200) {
    throw new Error(
      `create tryon task fail : ${response.data.message}`
    );
  }
  const taskResponse = response.data;

  if (!taskResponse) {
    throw new Error('Failed to create tryon task');
  }

  return taskResponse;
}

export async function queryTryonTask(taskid: string): Promise<TryonStatusResponse> {
  const response = await axios.post(`https://aitaskquery-agqnqisgya.cn-hangzhou.fcapp.run`, { task_id: taskid });
  if (response.status !== 200) {
    throw new Error(
      `create tryon task fail : ${response.status} ${response.statusText}`
    );
  }
  const taskResponse = response.data;

  if (!taskResponse) {
    throw new Error('Failed to create tryon task');
  }

  return taskResponse;
}


