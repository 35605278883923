import axios from 'axios';

interface UserInfo {
  name?: string;
  contact?: string;
  phone?: string;
  address?: string;
  enterpriseid?: string;
}

interface OrderItem {
  link: string;
  piece: number;
}

interface OrderSampleParam {
  user: UserInfo,
  order: OrderItem[]
}

export async function orderSample(reqParam: OrderSampleParam): Promise<void> {
  const response = await axios.post('https://h-orderservices-jwtzfaokal.cn-hangzhou.fcapp.run', reqParam);
  const data = response.data;
  if (response.status !== 200) {
    throw new Error(
      `Failed to order sample : ${response.status}`
    );
  }
  if (data.status !== 'SUCCEED') {
    throw new Error(data.message);
  }
}
