import axios from "axios";

const platformServicesUrl = 'https://platforservices-qmbdjmxsdo.cn-hangzhou.fcapp.run'

export interface PlatformServicesType {
  "type1": string | null
  "type2": string | null
  "type3": string | null
}

export interface PlatformServicesTypeResp {
  status: 'SUCCESS',
  data: PlatformServicesType[]
}

export const getPlatformServicesType = async () => {
  const resp = await axios.post<PlatformServicesTypeResp>(platformServicesUrl, {"method": "type"});
  return resp.data.data
}

interface PlatformServicesQuery {
  page: number
  type1: string
  type2: string
  type3: string
  search: string[]
}

export interface FilterResult {
  id:string;
  name:string;
  image:string[];
  sco:string[];
}

export interface PlatformServicesFilterResp {
  status: 'SUCCESS',
  result: {
    pageAmount:number,
    resultAmount:number,
    data:FilterResult[]
  }
}

export const getPlatformServicesResult = async ({page, type1, type2, type3,search}: PlatformServicesQuery) => {
  const resp = await axios.post<PlatformServicesFilterResp>(platformServicesUrl, {
    "method": "filter",
    "filter": {
      page,
      "pageSize": 10,
      type1,
      type2,
      type3,
      search
    }
  });
  return resp.data.result;
}