import {
  type DBSchema,
  type IDBPDatabase,
  type StoreKey,
  type StoreNames,
  type StoreValue,
  openDB, type IndexNames, type OpenDBCallbacks
} from 'idb';

type Order = 'next' | 'prev'

export class DBWrapper<T extends DBSchema> {
  private db: IDBPDatabase<T> | null = null;
  private readonly initPromise: Promise<void>;

  constructor(dbName: string, version: number, upgrade: OpenDBCallbacks<T>['upgrade']) {
    this.initPromise = this.initialize(dbName, version, upgrade);
  }

  private async initialize(dbName: string, version: number, upgrade: OpenDBCallbacks<T>['upgrade']) {
    this.db = await openDB<T>(dbName, version, {
      upgrade
    });
  }

  private async ensureInitialized(): Promise<void> {
    if (this.db === null) {
      await this.initPromise;
    }
  }

  async add<S extends StoreNames<T>>(storeName: S, value: StoreValue<T, S>, key?: IDBKeyRange | StoreKey<T, S>) {
    await this.ensureInitialized();
    await this.db!.add(storeName, value, key);
  }

  async get<S extends StoreNames<T>>(storeName: S, key: IDBKeyRange | StoreKey<T, S>) {
    await this.ensureInitialized();
    return this.db!.get(storeName, key);
  }

  async put<S extends StoreNames<T>>(storeName: S, value: StoreValue<T, S>, key?: IDBKeyRange | StoreKey<T, S>) {
    await this.ensureInitialized();
    await this.db!.put(storeName, value, key);
  }

  async getAll<S extends StoreNames<T>>(storeName: S, count?: number) {
    await this.ensureInitialized();
    return this.db!.getAll(storeName, undefined, count);
  }

  async getAllFromIndex<S extends StoreNames<T>, I extends IndexNames<T, S>>(storeName: S, indexName: I, count = 10, order: Order = 'next', range?: IDBKeyRange) {
    await this.ensureInitialized();

    let cursor = await this.db!.transaction(storeName)
      .store.index(indexName)
      .openCursor(range, order);

    const values: StoreValue<T, S>[] = [];
    while (cursor && values.length < count) {
      const value = cursor.value;

      values.push(value);
      cursor = await cursor.continue();
    }
    return values;
  }

  async delete<S extends StoreNames<T>>(storeName: S, key: IDBKeyRange | StoreKey<T, S>) {
    await this.ensureInitialized();
    await this.db!.delete(storeName, key);
  }

  async clear<S extends StoreNames<T>>(storeName: S) {
    await this.ensureInitialized();
    await this.db!.clear(storeName);
  }

  async searchByTerms<S extends StoreNames<T>, I extends IndexNames<T, S>>(
    storeName: S,
    indexName: I,
    searchTerm: string,
    count: number = 10,
    range?: IDBKeyRange
  ) {
    await this.ensureInitialized();

    let cursor = await this.db!.transaction(storeName)
      .store
      .index(indexName)
      .openCursor(range);

    const regex = new RegExp(searchTerm, 'i');

    const results: StoreValue<T, S>[] = [];
    const keys = new Set<StoreKey<T, S>>();

    while (cursor && results.length < count) {
      const keyAsString = String(cursor.key);
      if (regex.test(keyAsString) && !keys.has(cursor.primaryKey)) {
        keys.add(cursor.primaryKey);
        results.push(cursor.value);
      }
      cursor = await cursor.continue();
    }

    return results;
  }

  async getDbInstance(): Promise<IDBPDatabase<T>> {
    await this.ensureInitialized();
    return this.db!;
  }
}
