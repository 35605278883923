import * as fabric from 'fabric';
import type { FavoriteItem } from '@/views/favorite/typing.ts';
import { joinUrl } from '@repo/utils';
import { getAppConfig } from '@/utils';
import { $t } from '@/locales';

const { URL_PARSE_URL } = getAppConfig();
const baseProxyUrl = joinUrl(URL_PARSE_URL, 'proxy');

const canvas = new fabric.Canvas(undefined, {
  width: 840,
  height: 0,
  backgroundColor: 'white'
});

async function generate(properties: Record<string, string>, list: FavoriteItem[]) {
  const cardPadding = 30;
  let top = 20;

  function createText(label: string, value: string, labelLeft: number, valueLeft: number, top: number, fontSize: number = 14) {
    const labelText = new fabric.FabricText(label, {
      left: labelLeft,
      top,
      fontSize,
      fill: 'black'
    });
    const valueText = new fabric.Textbox(value, {
      left: valueLeft,
      top,
      fontSize,
      fill: 'black',
      width: 400,
      splitByGrapheme: true
    });
    return { labelText, valueText, height: valueText.height };
  }

  async function createCard(item: FavoriteItem, top: number) {
    const url = `${baseProxyUrl}?url=${item.thumb}?x-oss-process=image/resize,m_lfit,h_195,w_195,limit_0`;
    const img = await fabric.FabricImage.fromURL(url, {
      crossOrigin: 'anonymous'
    });
    const scaleFactor = Math.min(180 / img.width, 180 / img.height);
    img.scale(scaleFactor);
    img.set({
      left: 30 + (180 - img.getScaledWidth()) / 2,
      top: top + 10 + (180 - img.getScaledHeight()) / 2
    });
    canvas.add(img);

    const labels = [$t('common.name'), $t('common.id'), $t('common.quantity'), $t('common.memo')];
    const values = [item.id, item.memo, `${item.num}`];
    let height = 0;
    const padding = 15;

    labels.forEach((label, index) => {
      const {
        labelText,
        valueText,
        height: valueHeight
      } = createText(label, values[index] || '', 240, 380, top + height + padding, 30);
      canvas.add(labelText);
      canvas.add(valueText);
      height += valueHeight + padding;
    });

    const rect = new fabric.Rect({
      left: 20,
      top,
      width: 800,
      height: height + padding,
      stroke: '#999',
      rx: 7,
      ry: 7,
      strokeWidth: 1,
      fill: 'transparent'
    });
    canvas.add(rect);

    return height + padding + cardPadding;
  }

  const propertyLabels = [$t('address-book.company'), $t('address-book.contact'), $t('address-book.phone'), $t('address-book.address'), $t('address-book.memo'), $t('common.date')];
  const propertyKeys = ['company', 'contact', 'phone', 'address', 'memo', 'date'];
  propertyLabels.forEach((label, index) => {
    const propertyKey = propertyKeys[index] as string;
    const value = properties[propertyKey];
    const { labelText, valueText, height } = createText(label, value || '', 25, 200, top, 30);
    canvas.add(labelText);
    canvas.add(valueText);
    top += height + 10; // Add padding between rows
  });

  for (const item of list) {
    top += await createCard(item, top);
  }

// Adjust canvas height
  canvas.setDimensions({ height: top + cardPadding });
}


export async function exportImage(properties: Record<string, string>, list: FavoriteItem[]) {
  canvas.clear();
  canvas.set('backgroundColor', 'white');
  await generate(properties, list);

  const dataURL = canvas.toDataURL({
    format: 'png',
    multiplier: 1,
    quality: 1.0  // Set quality for JPEG if needed
  });

  return dataURL;
}
