import { createApp } from 'vue';
import App from '@/App.vue';
import { router } from '@/router';
import { initStores } from '@repo/stores';
import { Lazyload } from 'vant';
import { setupI18n, getLocale } from '@/locales';
import * as Sentry from '@sentry/vue';


async function bootstrap() {
  const app = createApp(App);

  await setupI18n(app, getLocale());

  initStores(app);
  Sentry.init({
    app,
    dsn: 'https://0adaca2f016db0bc129752a2a3fd0a8b@o4507976643837952.ingest.de.sentry.io/4507976659042384',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration()
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/cloudknit\.cn/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  app.use(router);
  app.use(Lazyload);
  app.mount('#app');
}

export { bootstrap };
