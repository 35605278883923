<script setup lang="ts">
import { Image } from '@/components';
import { type Favorite, getStyle3dFabricApi } from '@/api';
import { useRoute, useRouter } from 'vue-router';
import { useParamStore } from '@/store/param.ts';
import { storeToRefs, useRelationStore, useSelectionStore, useStyle3dStore } from '@repo/stores';
import { type FabricShareInfo, useStyle3d } from '@repo/style3d';
import { joinUrl } from '@repo/utils';
import axios, { type AxiosResponse } from 'axios';
import { getAppConfig } from '@/utils';
import { generateFabricInfo } from '@/utils';

const props = defineProps<{ fabrics: Favorite[] }>();

const router = useRouter();
const route = useRoute();
const { refreshLoadResourceByRoute } = useParamStore();

const selectionStore = useSelectionStore();
const relationStore = useRelationStore();
const paramStore = useParamStore();


const style3dStore = useStyle3dStore();
const { resolution } = storeToRefs(style3dStore);

const { OSS_BASE_URL, INDIVIDUAL_FABRIC_SHARES } = getAppConfig();
const fabricRootPath = `${OSS_BASE_URL}${INDIVIDUAL_FABRIC_SHARES}`;

const { loadFabric } = useStyle3d();

const onClick = async (item: Favorite) => {
  const { selectedFabricId } = selectionStore;
  const { query } = route;
  const { scoResource } = paramStore.resourceParam;

  if (selectedFabricId === '') {
    await router.replace({
      params: { type: item.resourceType, id: item.id },
      query: { ...query }
    });
    await refreshLoadResourceByRoute();
  } else {
    let fabricShareInfo: Omit<FabricShareInfo, 'mask' | 'metaData' | 'doc' | 'enterprise_id'>;
    if (item.resourceType === 'style3d') {
      const style3dFabric = await getStyle3dFabricApi(item.id);
      const { xhr_path, thumb_path, width, height } = style3dFabric.data;
      const generateRibInfo = scoResource.type !== 'style3d';

      fabricShareInfo = await generateFabricInfo(
        {
          info: { width, height },
          thumb: xhr_path || thumb_path
        },
        generateRibInfo
      );
    } else {
      const fabricRoot = joinUrl(fabricRootPath, item.id);
      const infoResult: AxiosResponse<FabricShareInfo> = await axios.get(joinUrl(fabricRoot, 'info.json'));
      fabricShareInfo = infoResult.data;
    }

    const found = relationStore.shapeList.find(item => item.id === selectedFabricId);
    const materialIndex = found?.materialIndex;

    const filteredList = relationStore.shapeList.filter(item => item.materialIndex === materialIndex);
    const updatedList = filteredList.map(item => {
      const fabricInfoType = item.type ? item.type : 'main';
      return {
        ...item,
        ...fabricShareInfo.info[fabricInfoType] ?? fabricShareInfo.info['main']
      };
    });

    if (item.resourceType === 'style3d') {
      await loadFabric(updatedList, '', resolution.value);
    } else {
      const fabricRoot = joinUrl(fabricRootPath, item.id);
      await loadFabric(updatedList, fabricRoot, resolution.value);
    }
  }
};
</script>

<template>
  <div
    style="padding: 0 5px;"
    v-for="(item) in props.fabrics"
    :key="item.id"
  >
    <Image
      :width="75"
      :height="75"
      :src="item.thumb"
      fit="scale-down"
      @click="()=>onClick(item)"
    />
  </div>
</template>

<style scoped>

</style>
