<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useOpenCv } from './useopencv';
import { Uploader as VanUploader, Image as VanImage, Loading as VanLoading } from 'vant';

declare let cv: any;
const isCvLoaded = ref(false);
const { cvloaded } = useOpenCv();

const imageSource = ref('');
const destImage = ref();
const canvasRef = ref();

const x1 = ref(0);
const y1 = ref(0);
const x2 = ref(0);
const y2 = ref(0);
const x3 = ref(0);
const y3 = ref(0);
const x4 = ref(0);
const y4 = ref(0);

const maxImageHeight = 320;
let imageScale = 1;

/** 鼠标点击允许误差 */
const mouseClickThreshold = 50;

/** 鼠标点中拖拽点编号 */
let mouseClickAnchor = -1;

let processImageTimer: number | undefined  = undefined;

onMounted(() => { });

watch(cvloaded, (newValue) => {
  isCvLoaded.value = newValue;
  console.log('opencv is loaded!');
});

const pipeProcessImage = function() {
  if (processImageTimer !== undefined) {
    clearTimeout(processImageTimer)
  }

  processImageTimer = setTimeout(processImage, 200);
}

const processImage = function () {
  if (!isCvLoaded) {
    return;
  }

  if (!imageSource.value) {
    return;
  }

  const image = cv.imread('source-image');
  const grayImage = new cv.Mat();

  let theWidth = Math.round(Math.abs(x2.value + x3.value - x1.value - x4.value) / imageScale * 0.5);
  let theHeight = Math.round(Math.abs(y3.value + y4.value - y1.value - y2.value) / imageScale * 0.5);
  let finalDestCoords = cv.matFromArray(4, 1, cv.CV_32FC2, [0, 0, theWidth - 1, 0, theWidth - 1, theHeight - 1, 0, theHeight - 1]); //
  let srcCoords = cv.matFromArray(4, 1, cv.CV_32FC2, [x1.value, y1.value, x2.value, y2.value, x3.value, y3.value, x4.value, y4.value].map(x => x / imageScale));
  let dsize = new cv.Size(theWidth, theHeight);
  let M = cv.getPerspectiveTransform(srcCoords, finalDestCoords);
  cv.warpPerspective(image, grayImage, M, dsize, cv.INTER_LINEAR, cv.BORDER_CONSTANT, new cv.Scalar());
  
  // 显示图像
  cv.imshow(canvasRef.value, grayImage);

  image.delete();
  grayImage.delete();

  const style = destImage.value.style;
  const b64image = canvasRef.value.toDataURL();
  style.backgroundRepeat = "repeat";
  style.backgroundImage = `url('${b64image}')`;
  style.width = `${theWidth * 2}px`;
  style.height = `${theHeight * 2}px`;
  style.backgroundPosition = '50% 50%';
}

const moveStart = function (ev: MouseEvent) {
  const distanceList: number[] = [
    Math.abs(x1.value - ev.offsetX) + Math.abs(y1.value - ev.offsetY),
    Math.abs(x2.value - ev.offsetX) + Math.abs(y2.value - ev.offsetY),
    Math.abs(x3.value - ev.offsetX) + Math.abs(y3.value - ev.offsetY),
    Math.abs(x4.value - ev.offsetX) + Math.abs(y4.value - ev.offsetY)
  ];

  mouseClickAnchor = -1;
  let min = mouseClickThreshold;
  for (let i = 0; i < 4; i++) {
    const d: number = distanceList[i] ?? mouseClickThreshold;
    if (d < mouseClickThreshold && d < min ) {
      mouseClickAnchor = i;
      min = d;
    }
  }

  switch (mouseClickAnchor) {
    case 0:
      x1.value = ev.offsetX;
      y1.value = ev.offsetY;
      break;

    case 1:
      x2.value = ev.offsetX;
      y2.value = ev.offsetY;
      break;

    case 2:
      x3.value = ev.offsetX;
      y3.value = ev.offsetY;
      break;

    case 3:
      x4.value = ev.offsetX;
      y4.value = ev.offsetY;
      break;
  }

  pipeProcessImage();
}

const moveAnchor = function (ev: MouseEvent) {
  if (mouseClickAnchor < 0) {
    return;
  }

  switch (mouseClickAnchor) {
    case 0:
      x1.value = ev.offsetX;
      y1.value = ev.offsetY;
      break;

    case 1:
      x2.value = ev.offsetX;
      y2.value = ev.offsetY;
      break;

    case 2:
      x3.value = ev.offsetX;
      y3.value = ev.offsetY;
      break;

    case 3:
      x4.value = ev.offsetX;
      y4.value = ev.offsetY;
      break;
  }

  pipeProcessImage();
}

const moveEnd = () => {
  mouseClickAnchor = -1;
}

const afterRead = async (item: any) => {
  console.log(item);
  imageSource.value = item.content;
};

const imageLoaded = (ev: Event) => {
    const img = <HTMLImageElement>ev.target;
  
    imageScale = maxImageHeight / img.height;
    console.log('imageScale', imageScale);

    const dx = img.width * imageScale / 3;
    const dy = img.height * imageScale / 3;

    x1.value = dx;
    y1.value = dy;
    x2.value = dx + dx;
    y2.value = y1.value;
    x3.value = x2.value;
    y3.value = dy + dy;
    x4.value = x1.value;
    y4.value = y3.value;

    processImage();
};

</script>

<template>
  <div v-if="!imageSource" class="container">
    <van-uploader v-if="isCvLoaded" class="mtop8" :after-read="afterRead" />
    <van-loading v-else class="mtop8" />
  </div>
  <div v-else class="container">
    <van-image id="placeholder-image" fit="fit" :src="imageSource" @mousedown="moveStart" @mousemove="moveAnchor" @mouseup="moveEnd">
      <template v-slot:default>
        <svg>
          <line :x1="x1" :y1="y1" :x2="x2" :y2="y2" style="stroke:rgb(255,0,0);stroke-width:2" />
          <line :x1="x2" :y1="y2" :x2="x3" :y2="y3" style="stroke:rgb(255,0,0);stroke-width:2" />
          <line :x1="x3" :y1="y3" :x2="x4" :y2="y4" style="stroke:rgb(255,0,0);stroke-width:2" />
          <line :x1="x4" :y1="y4" :x2="x1" :y2="y1" style="stroke:rgb(255,0,0);stroke-width:2" />

          <circle :cx="x1" :cy="y1" r="6" stroke="red" stroke-width="2" fill="white" />
          <circle :cx="x2" :cy="y2" r="6" stroke="red" stroke-width="2" fill="white" />
          <circle :cx="x3" :cy="y3" r="6" stroke="red" stroke-width="2" fill="white" />
          <circle :cx="x4" :cy="y4" r="6" stroke="red" stroke-width="2" fill="white" />
        </svg>
      </template>
    </van-image>
    
  </div>
  <div class="container mtop8">
    <div ref="destImage"></div>
  </div>
  <img id="source-image" style="visibility: hidden;" :src="imageSource" @load="imageLoaded"/>
  <canvas ref="canvasRef" style="visibility: hidden;" ></canvas>

</template>

<style scoped>

.container {
  display: flex;
  justify-content: center;
}

.mtop8 {
  margin-top: 8px;
}

#placeholder-image {
  position: relative;
  height: 320px;
}

#placeholder-image svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
</style>
