import { onMounted, onUnmounted, ref } from 'vue';

declare let cv: any;

export function useOpenCv() {

    const cvloaded = ref(false);

    onMounted(async () => {
        const elements = document.head.querySelectorAll("#myopencv");
        if (elements.length === 0) {
        const loadOpenCV = new Promise<void>((resolve) => {
            const script = document.createElement("script");
            script.id = "myopencv";
            script.async = true;
            script.src = "/js/opencv.js";
            script.onload = () => {
                resolve();
            };
            document.head.appendChild(script);
        });

        const delay = new Promise<void>(function (resolve) { 
            setTimeout(resolve, 1000); 
        }); 

        await loadOpenCV;

        let i = 0;
        while(!cv.Mat) {
            await delay;
            i++;

            if (i >= 10) {
                break;
            }
        }

        cvloaded.value = true;
    }
    });

    onUnmounted(() => {
        const elements = document.head.querySelectorAll("#myopencv");
        for (const elem of elements) {
            elem.remove();
        }

        (<any>window).cv = undefined;
    });

    return { cvloaded }
}