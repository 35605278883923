<script setup lang="ts">
import { Toolbar } from './components';
import { useFabricStore } from '@/store/fabric.ts';
import { Swipe, SwipeItem, CellGroup, Field } from 'vant';
import { computed } from 'vue';
import { ToolbarContainer, Image } from '@/components';
import { SvgOpenInNewIcon } from '@repo/icons';
import { isAbsoluteURL } from '@repo/utils';
import { useRelationStore } from '@repo/stores';
import type { Shape } from '@repo/style3d';
import { $t } from '@/locales';

const fabricStore = useFabricStore();
const videos = computed(() => {
  const carousels = fabricStore?.fabricInfo?.carousels || [];
  return [
    ...carousels.filter(item => item.type === 'video')
      .map(item => ({
        url: item.url,
        thumbnail: `${item.url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast`
      }))
  ];
});

const images = computed(() => {
  const carousels = fabricStore?.fabricInfo?.carousels || [];
  return [
    ...carousels.filter(item => item.type === 'picture').map(item => item.url)
  ];
});

const showSwiper = computed(() => videos.value.length || images.value.length);

const properties = computed(() => {
  return fabricStore.fabricProperty.map(item => {
    let value = item.value;
    if (item.key === 'fabricWeightPos') {
      value = item.value + ' g/(10x10cm)';
    } else if (item.key == 'fabricUnitPrice') {
      value = item.value + ' ' + $t('common.rmb');
    }
    return {
      ...item,
      value,
      isLink: isAbsoluteURL(value || '')
    };
  }) as Record<string, any>[];
});

const onClickField = (item: Record<string, any>) => {
  if (item.isLink) {
    location.href = item.value;
  }
};

function shapeTotalSquare(shapeList: Shape[]) {
  return shapeList.reduce((acc, item) => {
    acc += (item.square || 0);
    return acc;
  }, 0.0);

}

const relationStore = useRelationStore();
const styleWeightProperty = computed(() => fabricStore.styleProperty.find(item => item.key === 'styleWeight'));

const styleWeight = computed(() => {
  if (!styleWeightProperty.value) {
    return 0;
  }
  const shapeList = relationStore?.shapeList || [];

  let total = 0;
  const mainShapeList = shapeList.filter(item => item.type === 'main');
  const mainSquare = shapeTotalSquare(mainShapeList);
  total += (fabricStore.fabricInfo?.info?.gramWeight || 0) * mainSquare;

  const collarShapeList = shapeList.filter(item => item.type === 'collar');
  const collarSquare = shapeTotalSquare(collarShapeList);
  total += (fabricStore.fabricInfo?.info?.gramWeight_collar || 0) * collarSquare;

  const hemShapeList = shapeList.filter(item => item.type === 'hem');
  const hemSquare = shapeTotalSquare(hemShapeList);
  total += (fabricStore.fabricInfo?.info?.gramWeight_luowen || 0) * hemSquare;

  const placketShapeList = shapeList.filter(item => item.type === 'placket');
  const placketSquare = shapeTotalSquare(placketShapeList);
  total += (fabricStore.fabricInfo?.info?.gramWeight_menjing || 0) * placketSquare;

  return total;
});

const estimationPriceProperty = computed(() => fabricStore.styleProperty.find(item => item.key === 'estimationPrice'));
const estimationPrice = computed(() => {
  if (!estimationPriceProperty.value) {
    return 0;
  }
  return styleWeight.value / 1000 * (fabricStore.fabricInfo?.info?.unitPrice || 0);
});
</script>

<template>
  <div :class="$style.detailPage">
    <div :class="$style.container">
      <div :class="$style.swipeContainer" v-if="showSwiper">
        <Swipe height="300" lazy-render>
          <SwipeItem v-for="video in videos" :key="video">
            <div style="display: flex; align-items: center; justify-content: center;height: 100%;">
              <video height="250" preload="none" :poster="video.thumbnail" controls :autoplay="false">
                <source :src="video.url" type="video/mp4" />
              </video>
            </div>
          </SwipeItem>
          <SwipeItem v-for="image in images" :key="image">
            <div style="display: flex; align-items: center; justify-content: center;height: 100%;">
              <Image :src="image" :width="410" :height="300" fit="scale-down" />
            </div>
          </SwipeItem>
        </Swipe>
      </div>
      <CellGroup>
        <Field
          v-if="styleWeightProperty"
          :label="styleWeightProperty.name"
          :model-value="`` + styleWeight.toFixed(0) +' '+ 'g'"
          readonly
        />
        <Field
          v-if="estimationPriceProperty"
          :label="estimationPriceProperty.name"
          :model-value="`` + estimationPrice.toFixed(1) +' '+ $t('common.rmb')"
          readonly
        />
        <Field
          v-for="item of properties"
          :key="item.name"
          :label="item.name"
          :model-value="item.value"
          readonly
          @click="()=>onClickField(item)"
        >
          <template #button>
            <SvgOpenInNewIcon v-if="item.isLink" />
          </template>
        </Field>
      </CellGroup>
    </div>
    <ToolbarContainer :dark="false">
      <Toolbar />
    </ToolbarContainer>
  </div>
</template>

<style module>
.detailPage {
  position: relative;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.swipeContainer {
  height: 300px;
  padding: 5px 10px;
}
</style>
